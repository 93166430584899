<script>
import TemplateInput from '../../../organisms/TemplateInput';
import TemplateBase from '../TemplateBase';

export default defineNuxtComponent({
  components: { TemplateBase, TemplateInput },
  props: {
    stepElement: {
      type: Object,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inputToggle: false,
      filledValue: 'checkbox',
      labelCopy: 'checkbox',
    };
  },
  mounted() {
    this.filledValue = this.stepElement;
    this.labelCopy = this.stepElement.label;
  },
  methods: {
    updateCheckbox(event) {
      this.filledValue.selected = event.target.checked;
    },
    onChangeInput(event) {
      this.labelCopy = event.target.value;
    },
    updateOption() {
      this.labelCopy = this.filledValue.label;
      this.$emit('onElementChanged', this.stepId);
    },
    closeInput() {
      this.filledValue.label = this.labelCopy;
    },
  },
});
</script>

<template>
  <TemplateBase
    :element="stepElement"
    :placeholder="__('Label text field')"
    :step-id="stepId"
  >
    <template #templateContent>
      <div
        class="border-margin flex h-22 items-center space-x-4 rounded border border-athens-gray px-4 py-4.5"
      >
        <trailblazer-checkbox
          hide-label
          :checked="filledValue.selected"
          @change="updateCheckbox($event)"
        />
        <TemplateInput
          :option="filledValue"
          @close-input="closeInput"
          @update-option="updateOption"
        />
      </div>
    </template>
  </TemplateBase>
</template>
